$(function(){
  $('a[href^="#"]').click(function(){
    const speed = 800;
    const href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - 32;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    //return false;
  });
});
